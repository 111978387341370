import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, TextField, FormControl, FormLabel, CircularProgress } from "@mui/material";
import {MenuItem, Select } from "@mui/material";
import axios from "axios";
import { baseURL } from "../../baseURL";
import { useState } from "react";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import dayjs from 'dayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import './CustomDialog.css';

export default function CustomDialog({ isOpen, setIsOpen, getData, type = "hr" }) {
  const [adding, setAdding] = useState(false);
  const [companies, setCompanies] = React.useState([]);
  const [company, setCompany] = React.useState('');
  const [name, setName] = React.useState('');
  const [contact, setContact] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [hospitality, setHospitality] = React.useState('');
  const [branches, setBranches] = React.useState([]);
  const [linkedIn, setLinkedIn] = React.useState('');
  const [lastContacted, setLastContacted] = React.useState(null);
  const [status, setStatus] = React.useState('In Progress');
  const [noValidEmail, showNoValidEmail] = useState(false);
  const [noValidPhone, showNoValidPhone] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const statusColors = {
    "In Progress": "orange",
    "Cancelled": "red",
    "Awaiting Confirmation": "blue",
    "On hold": "cyan",
    "Completed": "green",
  };

  const handleClose = () => {
    setCompany('');
    setName('');
    setContact('');
    setEmail('');
    setHospitality('');
    setBranches([]);
    setLinkedIn('');
    setLastContacted(null);
    setStatus('In Progress');
    showNoValidEmail(false);
    showNoValidPhone(false);
    setErrorMessage(''); // Clear any validation error messages
    setIsOpen(false); // Close the dialog
 };

  const getCompaniesForAutocomplete = () => {
    if (type === "hr") {
      axios.get(baseURL + '/companies', {
        headers: {
          authorization: localStorage.getItem("authToken")
        }
      }).then((res) => {
        const uniqueCompanies = [...new Set(res.data.map(item => item.name))]; // Remove duplicates
        setCompanies(uniqueCompanies);
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  React.useEffect(() => {
    getCompaniesForAutocomplete();
  }, [type]);

  const handleBlur = (event) => {
    const { name, value } = event.target;

    if (name === "email") {
      const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
      showNoValidEmail(!isValidEmail);
    } else if (name === "contact") {
      const isValidPhone = /^[0-9]{10}$/.test(value);
      showNoValidPhone(!isValidPhone);
    }
  };

  const handleContactChange = (event) => {
    const { value } = event.target;

    // Only allow numeric input and restrict length to 10 digits
    if (/^\d*$/.test(value) && value.length <= 10) {
      setContact(value);
    }
  };

  const handleSave = () => {
    if (adding) return; // Prevent multiple requests

    setAdding(true);
    const isEmailValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
    const isPhoneValid = /^[0-9]{10}$/.test(contact);

    if (!email && !contact) {
      showNoValidEmail(true);
      showNoValidPhone(true);    
      setAdding(false);
      return;
    }

    if (email && !isEmailValid) {
      showNoValidEmail(true);
      setAdding(false);
      return;
    }

    if (contact && !isPhoneValid) {
      showNoValidPhone(true);
      setAdding(false);
      return;
    }

    const data = type === "hr"
      ? { name, email, phone: contact, company, linkedIn}
      : { name: company, email, phone: contact, hospitality, branches, lastContacted: lastContacted || null, status };

    const endpoint = type === "hr" ? '/hr' : '/company';

    axios.post(baseURL + endpoint, data, {
      headers: {
        authorization: localStorage.getItem("authToken")
      }
    }).then((res) => {
      getCompaniesForAutocomplete();
      setCompany('');
      setName('');
      setContact('');
      setEmail('');
      setHospitality('');
      setBranches([]);
      setLinkedIn('');
      setLastContacted(null);
      setStatus('');
      showNoValidEmail(false);
      showNoValidPhone(false);
      setErrorMessage(''); // Clear error message on success
      handleClose();
      getData();
    }).catch((err) => {
      console.error(err);
      const { response } = err;
      if (response && response.status === 400) {
        setErrorMessage(response.data.message); // Set error message from backend
      }
       else {
        setErrorMessage("An unexpected error occurred.");
      }
    }).finally(() => {
      setAdding(false);
    });
  };

  const filterOptions = (options, { inputValue }) => {
    return options.filter(option => option.toLowerCase().includes(inputValue.toLowerCase())).sort(function(a,b){
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
  };

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: "1.5rem" } }}
      >
        <div className = "inside-add-dialog">
        <DialogTitle className="add-dialog-title" id="alert-dialog-title">
          <AddCircleOutlineIcon/> {`Add New ${type === "hr" ? "HR" : "Company"}`}
        </DialogTitle>
        <hr className="edit-dialog-divider"/>
        <DialogContent className="edit-dialog-content">
          <FormControl fullWidth margin="dense" className="edit-label-input-group">
            <FormLabel className="edit-label">Company</FormLabel>
            <Autocomplete
              freeSolo
              options={companies}
              filterOptions={filterOptions} // Custom filtering function
              getOptionLabel={(option) => option}
              onChange={(event, value) => setCompany(value || '')}
              className="w-[calc(100%-100px)] !p-0 edit-input add-company-input"
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  fullWidth
                  className="!w-[100%] !p-0 !border-0"
                />
              )}
            />
          </FormControl>
          {type === "hr" && (
          <>
            <FormControl fullWidth margin="dense" className="edit-label-input-group">
              <FormLabel className="edit-label">Name</FormLabel>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="edit-input"
              />
            </FormControl>
          </>
          )}
          <FormControl fullWidth margin="dense" className="edit-label-input-group">
            <FormLabel className="edit-label">Contact</FormLabel>
            <input
              name="contact"
              value={contact}
              onChange={handleContactChange}
              onBlur={handleBlur}
              placeholder="1234567890"
              type="tel"
              margin="normal"
              required
              className="edit-input"
            />
          </FormControl>
          {noValidPhone && (
            <div className="invalid-details-text">
              Invalid phone number. Must be 10 digits.
            </div>
          )}
          <FormControl fullWidth margin="dense" className="edit-label-input-group">
            <FormLabel className="edit-label">Email</FormLabel>
            <input
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={handleBlur}
              placeholder="user@example.com"
              type="email"
              margin="normal"
              required
              className="edit-input"
            />
          </FormControl>
          {noValidEmail && (
            <p className="invalid-details-text">Invalid email format.</p>
          )}
          {type === "hr" && (
          <>
            <FormControl fullWidth margin="dense" className="edit-label-input-group">
              <FormLabel className="edit-label">LinkedIn</FormLabel>
              <input
                value={linkedIn}
                onChange={(e) => setLinkedIn(e.target.value)}
                placeholder="LinkedIn Profile URL"
                type="url"
                className="edit-input"
              />
            </FormControl>
          </>
          )}
          {type === "company" && (
          <>
          <FormControl fullWidth margin="dense" className="edit-label-input-group">
            <FormLabel className="edit-label">Hospitality</FormLabel>
            <input
              name="hospitality"
              value={hospitality}
              onChange={(e) => setHospitality(e.target.value)}
              placeholder="Enter Value"
              type="text"
              margin="normal"
              required
              className="edit-input"
            />
          </FormControl>
            <FormControl fullWidth margin="dense" className="edit-label-input-group">
              <FormLabel className="edit-label">Branches</FormLabel>
              <input
                value={branches}
                onChange={(event) => {
                  const { value } = event.target;
                  const branches = value.split(',').map(branch => branch.trim());
                  setBranches(branches);
                }}
                className="edit-input"
              />
            </FormControl>
            {/* <FormControl fullWidth margin="dense">
              <FormLabel>Last Contacted</FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={lastContacted? dayjs(lastContacted) : null}
                    onChange={(newValue) => {
                      // Ensure the value is formatted as ISO string
                      const formattedDate = newValue ? newValue.toISOString() : null;
                      setLastContacted(formattedDate);
                    }}
                    renderInput={(params) => <input {...params} margin="normal" className="edit-input"/>}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                  />
                </LocalizationProvider>
            </FormControl> */}
            
            <FormControl fullWidth margin="dense" className="edit-label-input-group">
              <FormLabel className="edit-label">Status</FormLabel>
              <Select
                value={status || ""}
                onChange={(e) => setStatus(e.target.value)}
                className="edit-input"
              >
                {Object.entries(statusColors).map(([status, color]) => (
                  <MenuItem key={status} value={status} className="status-select-item">
                    {/* Colored dot before the status text */}
                    <span
                      style={{
                        backgroundColor: color,
                        borderRadius: "50%",
                        border: '0.0px solid black',
                        display: "inline-block",
                        width: "9px",
                        height: "9px",
                        marginRight: "8px",
                      }}
                    ></span>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
          )}
          {errorMessage && (
            <p className="invalid-details-text">{errorMessage}</p>
          )}
        </DialogContent>
        
        <DialogActions className="edit-dialog-actions">
          <Button onClick={handleSave} disabled={adding} className="edit-ok-button">
            {adding ? (
              <CircularProgress size={20} />
            ) : "OK"
          }
          </Button>
          <Button onClick={handleClose} className="edit-cancel-button" disabled={adding}>Cancel</Button>
        </DialogActions>
        </div>
      </Dialog>
    </React.Fragment>
  );
}